import * as React from "react";
import * as styles from './About.module.css';
import labels from '../../assets/data/labels';

export default function About() {
    return (
        <div className={styles.aboutContainer}>
            <div className={styles.about}>{labels.about}</div>
            <img className={styles.profile} src="../../assets/img/biophoto-min.jpg" alt="about-profile" />
        </div>
    )
};